<template>
  <SpinnerComponent :start="spinner"/>
  <NavBar/>
  <!-- <SideBar /> -->

  <!-- <div id="content-app" :style="isDisplaySidebar ? { 'width': `calc(100% - ${ sidebarWidth })`  } : {'width': '100%'} "> -->
  <div id="content-app">
    <SideBar/>
    <router-view/>
  </div>

  <FooterBar/>

</template>

<script>

// import router from "@/router";
// import myUser from './myUser';
import SideBar from '@/components/sidebar/SideBar';
import NavBar from '@/components/NavBar';
import FooterBar from '@/components/Footer';

// import { sidebarWidth, isDisplaySidebar } from '@/components/sidebar/state'
import SpinnerComponent from '@/components/SpinnerComponent.vue'

export default {
  components: { 
    SpinnerComponent, 
    SideBar,
    NavBar,
    FooterBar,
  },
  data: () => ({
    spinner: true
  }),
  methods: {
    async getUnits() { 
      this.spinner = false;
    }
  },
  mounted() {
  },
  setup() {

    function getTheme() {
      const dartMode  = localStorage.getItem("DARK_MODE_SURIYE_STORE");
      // console.log(dartMode);
      if(dartMode !== null && dartMode == 'dark') {
        const bodyPage  = document.body;
        bodyPage.classList.toggle("dark-theme");
        document.querySelector('meta[name="theme-color"]').setAttribute("content", "#121212");

        if(document.getElementById("icon-theme")){
          const iconTheme = document.getElementById("icon-theme");
          iconTheme.setAttribute("name", "sunny-outline");
        }

        localStorage.setItem("DARK_MODE_SURIYE_STORE", "dark")
      }
    }

    setTimeout(() => {
      getTheme()
    }, 100);

    return { 
    }
  },
  beforeMount() {
    setTimeout(() => {
      this.getUnits()
    }, 500);
  },
  computed() {
    // this.getUnits()
    this.spinner = false; 
      console.log(this.spinner);
  }
}
</script>
<style>
:root {
  /* --public: #37b482;
  --public-rgb: 55, 180, 130;

  --color-secondary: #1d82a7;
  --color-secondary-rgb: 29, 130, 167; */

  --public: #1d82a7;
  --public-rgb: 29, 130, 167;

  --color-secondary: #37b482;
  --color-secondary-rgb: 55, 180, 130;

  --box-background: #fff;
  --box-background-rgb: 255, 255, 255;

  --color-text: #121212;
  --bg-background: #f7f7f7;
  --color-box-shadow-rgb: 0, 0, 0;

  --sidebar-bg-color: #2f855a;
  --sidebar-item-hover: #38a169;
  --sidebar-item-active: #276749;

  --font-family-droid: 'tajawal','Droid Arabic Kufi', sans-serif, tahoma;
  --border-radius: 1rem;
}
.dark-theme {
  /* color: #fff!important;
  background-color: #17181b !important; */

  /* --public: #37b482;
  --public-rgb: 55, 180, 130;

  --color-secondary: #1d82a7;
  --color-secondary-rgb: 29, 130, 167; */

  --public: #1d82a7;
  --public-rgb: 29, 130, 167;

  --color-secondary: #37b482;
  --color-secondary-rgb: 55, 180, 130;

  --box-background: #212225;
  --box-background-rgb: 33, 34, 37;

  --color-text: #fff;
  --bg-background: #121212;
  --color-box-shadow-rgb: 255, 255, 255;

  --sidebar-bg-color: #2f855a;
  --sidebar-item-hover: #38a169;
  --sidebar-item-active: #276749;
}
@font-face {
  font-family: 'Droid Arabic Kufi';
  src: url('../public/fonts/DroidArabicKufi.ttf');
	font-weight: normal;
	font-style: normal;
}
@font-face {
  font-family: 'tajawal';
  src: url('../public/fonts/Tajawal-Regular.ttf');
	font-weight: normal;
	font-style: normal;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  font-family: var(--font-family-droid);
}
body {
  background: var(--bg-background);
}
body.rtl {
  direction: rtl;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#content-app {
  /* position: relative; */
  width: 80%;
  margin-inline-start: 10%;
  padding: 26px 26px;
  min-height: 74vh;
  /* min-height: 150vh; */
  /* max-width: 770px;
  margin: 0px auto; */

}
/* width */
::-webkit-scrollbar {
  width: 4px;
  border-radius: 3px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 7px;
}
::-webkit-scrollbar-thumb {
  background: var(--public);
  border-radius: 7px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--color-secondary);
}
/* ====> START PUBLIC <====*/
.form-public {
  position: relative;
  background: var(--box-background);
  border-radius: 12px;
  box-shadow: 0px 5px 12px rgba(var(--color-box-shadow-rgb), .2);
  padding: 26px;
}
.form-public .title-form {
  color: var(--color-text);
  margin-bottom: 20px;
  text-align: start;
  font-size: 14px;
}
.form-public .row-input-public {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.form-public .row-input-public .box-input-public {
  width: 48%;
}
.form-public .row-input-public .box-input-public select {
  width: 100%;
  margin: 0px 12px;
}
.form-public .box-input-public {
  border: 1px solid var(--color-text);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 12px;
  padding: 5px 12px;
  height: 42px;
}
.form-public .box-input-public .icon-input {
  position: relative;
  top: 3px;
  color: var(--color-text);
}
.form-public .box-input-public input {
  border: unset;
  outline: unset;
  width: 100%;
  padding: 0px 12px;
  background: transparent;
  color: var(--color-text);
}
.form-public .box-input-public .input-btn {
  cursor: pointer;
  position: relative;
  top: 3px;
  color: var(--color-text);
}
.form-public .box-check-public {
  text-align: start;
  margin-bottom: 7px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}
.form-public .box-check-public .check-label-public{
  color: var(--color-text);
  font-size: 12px;
  padding: 0px 5px;
}
.form-public .form-public-btn {
  background: linear-gradient(22deg, rgba(var(--public-rgb), 100%) 0%, rgba(var(--public-rgb), 95%) 50%, rgba(var(--public-rgb), 100%) 100%);
  box-shadow: none;
  position: relative;
  height: 42px;
  border: unset;
  width: 100%;
  cursor: pointer;
  color: #fff;
}
.form-public .form-public-btn:hover {
  box-shadow: 0px 20px 20px -17px rgba(var(--public-rgb), 90%); 
}
.public-btn {
  background: linear-gradient(22deg, rgba(var(--public-rgb), 100%) 0%, rgba(var(--public-rgb), 95%) 50%, rgba(var(--public-rgb), 100%) 100%);
  box-shadow: none;
  position: relative;
  height: 42px;
  border: unset;
  width: 100%;
  cursor: pointer;
  color: #fff;
}
.public-btn:hover {
  box-shadow: 0px 20px 20px -17px rgba(var(--public-rgb), 90%); 
}
.public-secondary-btn {
  background: linear-gradient(22deg, rgba(var(--color-secondary-rgb), 100%) 0%, rgba(var(--color-secondary-rgb), 95%) 50%, rgba(var(--color-secondary-rgb), 100%) 100%);
  box-shadow: none;
  position: relative;
  height: 42px;
  border: unset;
  width: 100%;
  cursor: pointer;
  color: #fff;
}
.public-secondary-btn:hover {
  box-shadow: 0px 20px 20px -17px rgba(var(--color-secondary-rgb), 90%); 
}
.form-public .form-public-btn.loader,
.public-btn.loader  {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}
.form-public .form-public-btn.loader .public-loader,
.public-btn.loader .public-loader {
  display: block;
}
.content-empty-items {
  width: 100%;
  background: rgba(var(--box-background-rgb), .5); 
  padding: 20px;
  margin: 20px auto;
}
.content-empty-items div {
  font-size: 3rem;
  color: var(--color-text);
}
.content-empty-items .products-name {
  font-size: 12px;
  color: var(--color-text);
}
select {
  border: unset;
  color: var(--color-text);
  outline: unset;
  background: transparent;
}
select option {
  margin: 40px;
  background: var(--box-background);
  color: var(--color-text);
  text-shadow: unset;
}
.public-loader {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: public-loader 2s linear infinite;
}

@keyframes public-loader {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.btn-copy {
  cursor: pointer;
  transition: 0.2s;
}
.btn-copy:hover {
  transition: 0.2s;
  transform: scale(1.05);
}
/* ====> START offer <====*/
.card-ribbon {
  position: absolute;
  top: 0;
  overflow: hidden;
  right: -6px;
  width: calc(100% + 12px);
  height: 25px;
  z-index: 1;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.card-ribbon::before, .card-ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 3px solid #2980b9;
  border-top-color: transparent;
  border-left-color: transparent;
}
.card-ribbon::before {
  bottom: 0;
  left: 0;
}
.card-ribbon span {
  position: absolute;
  display: block;
  width: 100%;
  padding: 5px 0;
  background-color: #3498db;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 8px;
  text-transform: uppercase;
  text-align: center;
  left: 0;
  top: 0px;
}
.card-ribbon::after {
  bottom: 0;
  right: 0;
}

.rise.card-ribbon span {
  background-color: #f00;
}
.rise.card-ribbon::before, .rise.card-ribbon::after {
  border: 3px solid #d00404;
}

.not-available.card-ribbon span {
  background-color: #E91E63; 
}
.not-available.card-ribbon::before, .not-available.card-ribbon::after {
  border: 3px solid #cb0c4d;
}

.offer.card-ribbon span {
  background-color: #07bc0f; 
}
.offer.card-ribbon::before, .offer.card-ribbon::after {
  border: 3px solid #028608;
}
/* ====> END offer <====*/

/* ====> START Messages Pop <====*/
.swal2-popup {
  background: var(--box-background) !important;
}
.swal2-popup .swal2-title {
  font-size: 16px;
  color: var(--color-text);
}
.swal2-html-container {
  font-size: 13px !important;
  color: var(--color-text) !important;
  white-space: pre-line;
}
.swal2-confirm {
  background: var(--public) !important;
  color: #fff;
  outline: unset;
}
/* ====> END Messages Pop <====*/
/* ====> START Filter date <====*/
.box-filter-date {
  position: relative;
}
.box-filter-date .form-filter {
  position: relative;
  background: var(--box-background);
  border-radius: 12px;
  box-shadow: 0px 5px 12px rgba(var(--color-box-shadow-rgb), .2);
  padding: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}
.box-filter-date .form-filter .inputs {
  width: calc(100% - 79px);
}
.form-filter .box-input-filter {
  border: 1px solid var(--color-text);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 12px;
  height: 30px;
}
.form-filter .box-input-filter.start {
  border-top-left-radius: 12px;
  margin-bottom: 9px;
}
.form-filter .box-input-filter.end {
  border-bottom-left-radius: 12px;
}
.form-filter .box-input-filter .icon-input {
  position: relative;
  top: 3px;
  color: var(--color-text);
}
.form-filter .box-input-filter input {
  border: unset;
  outline: unset;
  width: 100%;
  padding: 0px 12px;
  background: transparent;
  color: var(--color-text);
}
.btn-filter {
  background: var(--public);
  color: #fff;
  border: unset;
  width: 69px;
  height: 69px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  cursor: pointer;
}
/* ====> START Filter date <====*/
body.rtl .btn-filter {
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
body.rtl .form-filter .box-input-filter.start {
  border-top-left-radius: unset;
  border-top-right-radius: 12px;
}
body.rtl .form-filter .box-input-filter.end {
  border-bottom-left-radius: unset;
  border-bottom-right-radius: 12px;
}
/* body.rtl #content-app {
  margin-left: unset !important;
} */
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}


/* ====> START Screen Phone <====*/
@media (min-width: 450px) {
  
}
@media (max-width: 992px) {
  #content-app {
    width: 100%;
    margin-inline-start: unset !important;
  } 
}
/* ====> END Screen Phone <====*/
</style>
