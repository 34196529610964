import { createI18n } from 'vue-i18n'
import en from './locales/en.json'
import ar from './locales/ar.json'
import tr from './locales/tr.json'

function loadLocaleMessages() {
  const locales = [{ en: en }, { ar: ar }, { tr: tr }]
  const messages = {}
  locales.forEach(lang => {
    const key = Object.keys(lang)
    messages[key] = lang[key] 
  })
  return messages
}

function getStartingLocale() {
  if (localStorage.getItem('MY_LANG_SURIYE_STORE')) {

    if(localStorage.getItem('MY_LANG_SURIYE_STORE') === 'ar'){
      document.body.classList.remove("ltr");
      document.body.classList.add("rtl");
    } else {
      document.body.classList.remove("rtl");
      document.body.classList.add("ltr");
    }
    
    return localStorage.getItem('MY_LANG_SURIYE_STORE')
  }
  return "en"
}

export default createI18n({
  locale: getStartingLocale(), //localStorage.getItem('MY_LANG_SURIYE_STORE') || 'en',
  fallbackLocale: getStartingLocale(), //localStorage.getItem('MY_LANG_SURIYE_STORE') || 'en',
  messages: loadLocaleMessages()
})