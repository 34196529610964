<template>
  <!-- <div class="c--navbar" :style="isDisplaySidebar ? { 'width': `calc(100% - ${ sidebarWidth })`  } : {'width': '100%'} "> -->
  <div class="c--navbar" v-if="isDisplayNavbar">
    <div class="content-navbar">
      <div class="navbar-start">
        <div id="btn_toggle_menu" class="btn-toggle-menu" @click="toggleMenu" v-if="isBtnSidebar">
          <span class="l-first"></span>
          <span class="l-second"></span>
          <span class="l-third"></span>
        </div>

        <!-- <div id="btn_toggle_menu" class="icon" @click="toggleMenu" :class="{ 'rotate-180': collapsed }">
          <div class="first">
            <div class="left"></div>
            <div class="right"></div>
          </div>
          <div class="second"></div>
          <div class="last">
            <div class="left"></div>
            <div class="right"></div>
          </div>
        </div> -->
        <img class="logo" alt="{{ $t('_public._name_app') }}" src="../assets/logo.png">
        <h3 class="name-app">{{ $t('_public._name_app') }}</h3>
        
      </div>
      <div class="navbar-end">
        <SidebarLink v-if="isLogin === 'yes'" to="/logout" icon="log-out-outline">{{ $t('_sidebar._Logout') }}</SidebarLink>
        <SidebarLink v-if="isLogin === 'no'" to="/login" icon="log-in-outline">{{ $t('_sidebar._Login') }}</SidebarLink>
        <SidebarLink v-if="isLogin === 'no'" to="/register" icon="person-add-outline">{{ $t('_sidebar._Register') }}</SidebarLink>
      </div>
    </div>
  </div>
</template>

<script>
// import { sidebarWidth, isDisplaySidebar } from '@/components/sidebar/state';
import { collapsed, isDisplaySidebar, showSidebar, hideSidebar, isDisplayNavbar, isBtnSidebar } from '@/components/sidebar/state';
import SidebarLink from './sidebar/SidebarLink';
import myUser from '../myUser';

export default {
  name: 'NavBar',
  props: {
    msg: String
  },
  data() {

    return {
      isLogin: myUser.isLogin
    }
  },
  components: { SidebarLink },
  methods: {
    async toggleMenu(){ // btn_toggle_menu
      if(document.getElementById("btn_toggle_menu")){
        // const btn_toggle_menu = document.getElementById("btn_toggle_menu");
        // btn_toggle_menu.classList.toggle('active');
        // console.log("toggleMenu", btn_toggle_menu);
        // toggleSidebar()
        // console.log(isDisplaySidebar.value)
        if(isDisplaySidebar.value) {
          hideSidebar()
        } else {
          showSidebar()
        }
      }
    }
  },
  setup() {
    return { 
      // sidebarWidth, 
      // isDisplaySidebar,
      collapsed,
      isBtnSidebar,
      isDisplayNavbar
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.c--navbar {
  position: sticky;
  top: 0;
  /* left: 0; */
  z-index: 9999;
}
.content-navbar {
  position: relative;
  height: 65px;
  padding: 15px;
  background: var(--box-background);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.content-navbar .navbar-start,
.content-navbar .navbar-end {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
}
.btn-toggle-menu {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: relative;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.btn-toggle-menu .l-first,
.btn-toggle-menu .l-second,
.btn-toggle-menu .l-third {
  width: 20px;
  height: 2px;
  background: var(--color-text);
  display: block;
  border-radius: 2px;
  transition: all 0.2s;
  animation: scales 0.6s;
}
.btn-toggle-menu .l-second{
  margin: 3px 0px;
}
.content-navbar .navbar-start .logo {
  width: 48px;
  padding-inline-start: 15px;
}
.content-navbar .navbar-start .name-app {
  font-size: 14px;
  color: var(--color-text);
  margin: 0;
  padding-inline-start: 9px;
  font-weight: normal;
}
.btn-toggle-menu.active .l-second {
  display: none;
}
.btn-toggle-menu.active .l-first {
  transform: rotateZ(-45deg);
  animation: rotateFirst 0.5s;
}
.btn-toggle-menu.active .l-third {
  transform: rotateZ(45deg);
  animation: rotateThird 0.5s;
}

.navbar-end .link {
  padding: 1em 1em 1em 0.25em;
  font-size: 12px !important;
  margin-inline-start: 0.5em;
}
/* ====> START BTN MENU <====*/
@keyframes rotateFirst {
  0%{
    transform: rotateZ(0deg);
  }
  35%{
    transform: rotateZ(180deg);
  }
  70%{
    transform: rotateZ(360deg);
  }
  100%{
    transform: rotateZ(-45deg);
  }
}
@keyframes rotateThird {
  0%{
    transform: rotateZ(360deg);
  }
  35%{
    transform: rotateZ(90deg);
  }
  70%{
    transform: rotateZ(180deg);
  }
  100%{
    transform: rotateZ(45deg);
  }
}
@keyframes scales {
  0%{
    transform: scale(0.3)
  }
  30%{
    transform: scale(1.2)
  }
  60%{
    transform: scale(0.9)
  }
  100%{
    transform: scale(1)
  }
}
/* ====> END BTN MENU <====*/
</style>
