<template >

  <div class="overflow" v-if="isDisplaySidebar" @click="hideSidebar"></div>
  <div class="sidebar" :style="{ width: sidebarWidth }" :class="isDisplaySidebar ? 'show' : '' " > <!--v-if="isDisplaySidebar"-->


    <h1 class="name-web" v-if="isLogin === 'no'">
      <span v-if="collapsed">
        <div>{{ $t('_public._name_app_a') }}</div>
        <div>{{ $t('_public._name_app_b') }}</div>
      </span>
      <span v-else>{{ $t('_public._name_app') }}</span>
    </h1>

    <div class="content-user-sidebar" v-if="isLogin === 'yes'">
     

      <div class="box-user group">
        <span class="btn-copy" @click="copy(id_user)"><ion-icon name="finger-print-outline"></ion-icon></span>
        <img class="avatar" :alt="user" :src="avatar">
        <h6>{{ user }}</h6>
      </div>

      <div class="box-user group">
        <img v-if="logoGroup" class="logo-group" :alt="nameGroup" :src="logoGroup">
        <h6>{{ nameGroup }}</h6>
      </div>
      
      <div class="box-user">
        <h6>{{ $t('_debts._balance') }}</h6>
        <p @click="goToPage('wallet')">{{ sumBalance }} <span class="code_currency">{{ code_currency }}</span></p>
      </div>
      <div class="box-user" v-if="sumBalanceDebt > 0">
        <h6>{{ $t('_debts._debts') }}</h6>
        <p @click="goToPage('debts')">{{ sumBalanceDebt }} <span class="code_currency">{{ code_currency }}</span></p>
      </div>


    </div>

    <SidebarLink to="/" icon="home-outline" @click="hideSidebar">{{ $t('_sidebar._Home') }}</SidebarLink>
    <SidebarLink to="/categories/all" icon="grid-outline" @click="hideSidebar">{{ $t('_sidebar._Categories') }}</SidebarLink>
    <SidebarLink v-if="isLogin === 'yes'" to="/wallet" icon="wallet-outline" @click="hideSidebar">{{ $t('_sidebar._Wallet') }}</SidebarLink>
    <SidebarLink v-if="isLogin === 'yes'" to="/orders" icon="bag-outline" @click="hideSidebar">{{ $t('_sidebar._Orders') }}</SidebarLink>
    <SidebarLink v-if="isLogin === 'yes'" to="/favorite" icon="heart-outline" @click="hideSidebar">{{ $t('_sidebar._Favorite') }}</SidebarLink>
    <SidebarLink v-if="isLogin === 'yes'" to="/profile" icon="person-outline" @click="hideSidebar">{{ $t('_sidebar._Profile') }}</SidebarLink>
    <SidebarLink to="/authorized-agents" icon="chatbubbles-outline" @click="hideSidebar">{{ $t('_sidebar._Authorized_Agents') }}</SidebarLink>
    <SidebarLink to="/charging-methods" icon="cash-outline" @click="hideSidebar">{{ $t('_sidebar._Charging_Methods') }}</SidebarLink>
    <SidebarLink v-if="isLogin === 'yes'" to="/activity" icon="document-text-outline" @click="hideSidebar">{{ $t('_sidebar._Activity') }}</SidebarLink>
    <SidebarLink v-if="isAPI === true" to="/api" icon="hardware-chip-outline" @click="hideSidebar">API</SidebarLink>
    <SidebarLink to="/about" icon="settings-outline" @click="hideSidebar">{{ $t('_sidebar._About') }}</SidebarLink>
    <SidebarLink v-if="isLogin === 'yes'" to="/logout" icon="log-out-outline" @click="hideSidebar">{{ $t('_sidebar._Logout') }}</SidebarLink>
    <SidebarLink v-if="isLogin === 'no'" to="/login" icon="log-in-outline" @click="hideSidebar">{{ $t('_sidebar._Login') }}</SidebarLink>

    <div class="box-setings">

      <span class="content-mode-theme"><ion-icon @click="switchTheme" id="icon-theme" name="moon-outline"></ion-icon></span>
    
      <div class="locale-changer">
        <select id="select-lang-public" v-model="$i18n.locale" @change="changeSwitchLang">
          <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{ locale }}</option>
        </select>
      </div>

    </div>

    <!-- <span class="collapse-icon" :class="{ 'rotate-180': collapsed }" @click="toggleSidebar">X</span> -->
  </div>
</template>
  
<script>
import SidebarLink from './SidebarLink';
import { collapsed, toggleSidebar, sidebarWidth, isDisplaySidebar, hideSidebar} from './state';
import router from "@/router";
import myUser from '../../myUser';
import axios from 'axios';
import { toast } from "vue3-toastify";
import 'vue3-toastify/dist/index.css';

export default {
  name: 'SideBar',
  props: {
    msg: String
  },
  data() {

    return {
      isLogin: myUser.isLogin,
      isAPI: false,
      id_user: myUser.id_user,
      user: myUser.user,
      avatar: myUser.avatar,
      code_currency: myUser.code_currency,
      sumBalance: null,
      sumBalanceDebt: null,
      logoGroup: null,
      nameGroup: null
    }
  },
  methods: {
    async checkLogin(){
      if (myUser.isLogin == 'yes') {
        this.getDataView();
        console.log(myUser);
      }
    },
    async getDataView(){
      this.checkAPIUser();
      this.getSumMyBalance();
      this.getSumMyBalanceDebt();
      this.getNameGroup();
    },
    async checkAPIUser(){
      // this.isLoading = false;
      const inputs = {'idus': myUser.id_user, 'token': myUser.token} 
      await axios.postForm('app_settings.php?action=checkAPIUser', inputs).then((res) => {
        // console.log(res['data'])
        if (res['data']['msg'] == 'ok') {
          this.isAPI = true;
        } else {
          this.isAPI = false;
        }
      }).catch(() => {  });
    },
    async getSumMyBalance(){
      // this.isLoading = false;
      const inputs = {'lang': this.lang, 'idus': myUser.id_user, 'token': myUser.token, 'id_person': myUser.id_user} 
      await axios.postForm('wallet.php?action=getSumMyBalance', inputs).then((res) => {
        // console.log(res['data'])
        if (res['data']['msg'] == 'ok') {
          this.sumBalance = res['data']['amount'];
        } else {
          this.sumBalance = 0;
        }
      }).catch(() => {  });
    },
    async getSumMyBalanceDebt(){
      const inputs = {'lang': this.lang, 'idus': myUser.id_user, 'token': myUser.token, 'id_person': myUser.id_user} 
      await axios.postForm('wallet.php?action=getSumMyBalanceDebt', inputs).then((res) => {
        // console.log(res['data'])
        if (res['data']['msg'] == 'ok') {
          this.sumBalanceDebt = res['data']['amount'];
        } else {
          this.sumBalanceDebt = 0;
        }
      }).catch(() => {  });
    },
    async getNameGroup(){
      const inputs = {'lang': this.lang, 'idus': myUser.id_user, 'token': myUser.token} 
      await axios.postForm('wallet.php?action=getNameGroup', inputs).then((res) => {
        // console.log(res['data'])
        if (res['data']['msg'] == 'ok') {
          this.logoGroup = res['data']['logo_group'];
          this.nameGroup = res['data']['nameGroup'];
        } else {
          this.nameGroup = 'NO';
        }
      }).catch(() => {  });
    },
    async switchTheme(){
      // console.log("switchTheme")
      const bodyPage  = document.body;
      const iconTheme = document.getElementById("icon-theme");

      if (bodyPage.classList.contains("dark-theme")) { 
        bodyPage.classList.toggle("dark-theme");
        iconTheme.setAttribute("name", "moon-outline");
        localStorage.setItem("DARK_MODE_SURIYE_STORE", "light");
        document.querySelector('meta[name="theme-color"]').setAttribute("content", "#37b482");
      } else {
        bodyPage.classList.toggle("dark-theme");
        iconTheme.setAttribute("name", "sunny-outline");
        localStorage.setItem("DARK_MODE_SURIYE_STORE", "dark");
        document.querySelector('meta[name="theme-color"]').setAttribute("content", "#121212");
      }
      
      // const dartMode  = localStorage.getItem("DARK_MODE_SURIYE_STORE");
      // console.log(dartMode);
    },
    async changeSwitchLang(e) {
      const n_lang = e.target.value;
      // console.log("change", n_lang)
      localStorage.setItem("MY_LANG_SURIYE_STORE", n_lang);
      if(n_lang === 'ar'){
        document.body.classList.remove("ltr");
        document.body.classList.add("rtl");
      } else {
        document.body.classList.remove("rtl");
        document.body.classList.add("ltr");
      }
    },
    hideSidebar(){
      hideSidebar();
    },
    async copy(mytext) {
      try {
        await navigator.clipboard.writeText(mytext);
        // alert('Copied');
        toast.success("Copy", {autoClose: 1000,});
      } catch($e) {
        toast.error("Cannot copy", {autoClose: 1000,});
      }
    },
    async goToPage(page){
      hideSidebar();
      router.push({ path: `/${page}` })
    }
    // checkLogin(){
    //   this.isLogin = false
    //   // return true
    // }
  },
  mounted(){
    this.checkLogin()
  },
  components: { SidebarLink },
  setup(){
    return { collapsed, toggleSidebar, sidebarWidth, isDisplaySidebar }
  }
}
</script>

<style scoped>
.content-user-sidebar {
  display: flex;
  padding: 12px;
  border-radius: 5px;
  /* margin-top: 20px; */
  margin-bottom: 12px;
  flex-direction: column;
  flex-wrap: nowrap;
  box-shadow: 0px 5px 20px rgba(var(--public-rgb), .1);
  background: rgba(var(--public-rgb), .2);
}
.content-user-sidebar .box-user {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 9px;
}
.content-user-sidebar .box-user.group {
  justify-content: flex-start;
  direction: ltr;
}
.content-user-sidebar .box-user .btn-copy {
  position: absolute;
  left: 30px;
  top: 0;
}
.content-user-sidebar .box-user .avatar {
  max-width: 45px;
  max-height: 45px;
  width: 45px;
  height: 45px;
  border: 1px solid rgba(var(--color-box-shadow-rgb), .3);
  border-radius: 50%;
  margin-inline-end: 10px;
}
.content-user-sidebar .box-user .logo-group {
  max-width: 32px;
  max-height: 32px;
  width: 45px;
  height: 45px;
  margin-inline-end: 10px;
}
.content-user-sidebar .box-user h6 {
  color: var(--color-text);
  user-select: none;
  font-size: 11px;
}
.content-user-sidebar .box-user p {
  color: var(--public);
  cursor: pointer;
  font-size: 13px;
}
.overflow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(var(--public-rgb), .2);
  z-index: 10000;
}
.sidebar {
  color: var(--color-text);
  /* background-color: var(--sidebar-bg-color); */
  background-color: var(--box-background);
  float: left;
  position: fixed;
  z-index: 10001;
  top: 0;
  left: -300px;
  bottom: 0;
  padding: 0.5em;

  transition: 0.4s ease;

  display: flex;
  flex-direction: column;
  height: 100vh;
}
.sidebar.show {
  left: 0px;
  transition: 0.4s ease;
}
.sidebar .name-web {
  font-size: 16px;
  margin: 26px 0px;
  text-transform: uppercase;
}
.collapse-icon {
  position: absolute;
  bottom: 0;
  padding: 0.75em;
  color:  var(--color-text);
  cursor: pointer;
  transition: 0.2s linear;
}

.rotate-180 {
  transform: rotate(180deg);
  transition: 0.2s linear;
}
.content-mode-theme {
  /* margin-top: 15px; */
  height: 40px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  color: var(--color-text);
  margin-inline-end: 9px;
}
.content-mode-theme  #icon-theme {
  width: 40px;
  font-size: 16px;
  cursor: pointer;
}
/* body.rtl .sidebar {
  left: unset;
  right: 0;
} */
.locale-changer {
  position: relative;
  width: 100%;
  border: 1px solid var(--color-text);
  /* margin-bottom: 12px; */
  border-radius: 5px;
}
.locale-changer select {
  border: unset;
  width: 96%;
  color: var(--color-text);
  outline: unset;
  background: transparent;
  height: 22px;
}
.locale-changer select option {
  margin: 40px;
  background: var(--box-background);
  color: var(--color-text);
  text-shadow: unset;
}
.box-setings {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 9px;
}
/* body.rtl .content-mode-theme {
  right: unset;
  left: -20px;
  box-shadow: -8px -4px 13px rgba(var(--color-box-shadow-rgb), 0.2);
} */
body.rtl .sidebar {
  left: unset;
  right: -300px;
  float: right;
}
body.rtl .sidebar.show {
  left: unset;
  right: 0px;
  float: right;
}
</style>
  